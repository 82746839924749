import React from 'react';
import { connect } from 'react-redux';
import ContactComponent from '../components/contact';
import { updatePath } from '../redux/actions';
import PropTypes from 'prop-types';
import GenerateReact from '../components/generate-react';
import { graphql, useStaticQuery } from 'gatsby';
import { Divider } from '@material-ui/core';
import { Helmet } from 'react-helmet';
const Contact = (props) => {
  const data = useStaticQuery(query);
  React.useEffect(() => {
    props.reduxUpdatePath(props.uri);
  }, []);

  return (
    <>
      <Helmet title="Contact | Bellisima" />
      <GenerateReact data={data} />
      <ContactComponent />
      <Divider style={{ marginTop: 24 }} />
    </>
  );
};
const query = graphql`
  query ContactPage {
    page: contentfulPage(contentful_id: { eq: "7tyzfH1LVdYaCq2oCYBkhC" }) {
      ...DataTypes
    }
  }
`;
Contact.propTypes = {
  uri: PropTypes.string,
  reduxUpdatePath: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  reduxUpdatePath: (data) => dispatch(updatePath(data)),
});
export default connect(null, mapDispatchToProps)(Contact);
